import styled from 'styled-components'
import { Grid } from '@material-ui/core/'
import { breakpoint } from 'src/styles/mixins'
import { LinkWithArrow } from 'src/styles/typography'

export const Heading = styled.h1`
  font-family: ${props => props.theme.font.primary};
  font-weight: 200;
  font-size: 3.75rem;
  line-height: 1.2;
  margin: 24px 0 0 0;
  color: ${props => props.theme.color.black};
`

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
`

export const StyledGridItemTop = styled(Grid)`
  padding-bottom: 0 !important;

  ${breakpoint.lg`
    padding-bottom: 32px !important;
  `}
`

export const StyledGridItemBottom = styled(Grid)`
  padding-top: 0 !important;

  ${breakpoint.lg`
    padding-top: 32px !important;
  `}
`

export const ReorderText = styled.div`
  order: 2;

  ${breakpoint.lg`
    order: 1;
  `}
`

export const ReorderImage = styled.div`
  order: 1;

  ${breakpoint.lg`
    order: 2;
  `}
`

export const StyledLinkWithArrow = styled(LinkWithArrow)`
  margin-top: 16px;
  display: block;
`
