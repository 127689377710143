import React from 'react'
import PropTypes from 'prop-types'
import Atf from 'src/sections/Atf'
import DevelopmentFields from 'src/sections/DevelopmentFields'
import Partners from 'src/sections/Partners'
import Section from 'src/components/Section'
import { Grid, Box } from '@material-ui/core/'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import LinkButton from 'src/components/LinkButton'
import LinkBox from 'src/components/LinkBox'
import SectionPattern from 'src/components/SectionPattern'
import AnimatedSeperator from 'src/components/AnimatedSeperator'
import Cards from 'src/sections/Cards'
import ContactCta from 'src/sections/contact/ContactCta'
import { getRelativeUrl } from 'src/utils/urlUtils'
import { getThemeVar, breakpoint } from 'src/styles/mixins'
import { v4 as uuidv4 } from 'uuid'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from 'styled-components'

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -72px;

  ${breakpoint.lg`
    margin-top: 0;
    flex-direction: row;
  `}
`

export const HomeTemplate = ({ acf }) => {
  const atf = acf.homeAtf
  const boxes = acf.homeBoxes
  const intro = acf.homeIntro
  const skills = acf.homeSkills
  const developmentFields = acf.homeDevelopmentFields
  const services = acf.homeServices
  const partners = acf.homePartners
  const contact = acf.homeContact

  const mappedBoxes = boxes.map(box => {
    return (
      <LinkBox
        key={uuidv4()}
        heading={box.heading}
        text={box.text}
        color={box.color}
        relativeUrl={getRelativeUrl(box.link.url)}
      />
    )
  })

  const breakpoints = useBreakpoint()

  return (
    <>
      <Atf>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Heading2Line
              preHeading={atf.preHeading}
              heading={atf.heading}
              color={getThemeVar('color.white')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Wysiwyg color="white" sizeLg="22px">
              {atf.textWysiwyg}
            </Wysiwyg>
            <Box mt={4} mb={{xs: 8, lg: 11}}>
              <LinkButton to={getRelativeUrl(atf.link.url)}>
                {atf.link.title}
              </LinkButton>
            </Box>
          </Grid>
        </Grid>
      </Atf>

      <Section background={getThemeVar('color.lightblue')} hasPadding>
        <BoxContainer>{mappedBoxes}</BoxContainer>
        <Box mt={11}>
          <Heading2Line preHeading={intro.preHeading} heading={intro.heading} />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} md={5}>
              <Wysiwyg>{intro.textColOneWysiwyg}</Wysiwyg>
            </Grid>
            {breakpoints.md && (
              <Grid item xs={12} md={2}>
                <AnimatedSeperator />
              </Grid>
            )}
            <Grid item xs={12} md={5}>
              <Wysiwyg>{intro.textColTwoWysiwyg}</Wysiwyg>
            </Grid>
          </Grid>
        </Box>
        <SectionPattern positionX="right" />
      </Section>

      <Section hasPaddingLarge>
        <Heading2Line preHeading={skills.preHeading} heading={skills.heading} />
        <Cards data={skills.skillsRepeater}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Box mt={{ xs: 4, lg: 10 }}>
              <LinkButton to={getRelativeUrl(skills.link.url)}>
                {skills.link.title}
              </LinkButton>
            </Box>
          </Grid>
        </Cards>
        <SectionPattern positionX="left" />
      </Section>

      <Section background={getThemeVar('color.lightblue')} hasPaddingLarge>
        <Heading2Line
          preHeading={developmentFields.preHeading}
          heading={developmentFields.heading}
        />
        <DevelopmentFields fields={developmentFields.repeater} />
        <SectionPattern positionX="right" />
      </Section>

      <Section hasPaddingLarge>
        <Heading2Line
          preHeading={services.preHeading}
          heading={services.heading}
        />
        <Cards data={services.repeater}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Box mt={{ xs: 4, lg: 10 }}>
              <LinkButton to={getRelativeUrl(services.link.url)}>
                {services.link.title}
              </LinkButton>
            </Box>
          </Grid>
        </Cards>
        <SectionPattern positionX="left" />
      </Section>

      <Partners partners={partners} />

      <ContactCta
        heading={contact.heading}
        textWysiwyg={contact.textWysiwyg}
        link={contact.link}
        beforeFooter
      />
    </>
  )
}

HomeTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  acf: PropTypes.shape({
    homeAtf: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),

    homeBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        link: PropTypes.shape({
          url: PropTypes.string,
        }),
        color: PropTypes.string,
      })
    ),

    homeIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textColOneWysiwyg: PropTypes.string,
      textColTwoWysiwyg: PropTypes.string,
    }),

    homeSkills: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      skillsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.object, // eslint-disable-line
          heading: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ),
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),

    homeDevelopmentFields: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      repeater: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          textWysiwyg: PropTypes.string,
          link: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          }),
          image: PropTypes.object, // eslint-disable-line
        })
      ),
    }),

    homeServices: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      repeater: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.object, // eslint-disable-line
          heading: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ),
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),

    homePartners: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      testimonial: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        textWysiwyg: PropTypes.string,
        portrait: PropTypes.object, // eslint-disable-line
      }),
      logos: PropTypes.arrayOf(
        PropTypes.shape({
          portrait: PropTypes.object, // eslint-disable-line
        })
      ),
    }),

    homeContact: PropTypes.shape({
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default HomeTemplate
