import React from 'react'
import PropTypes from 'prop-types'
import ATFVideo from 'src/assets/home-atf-video.mp4'

import {
  AtfFull,
  AtfInner,
  VideoContainer,
  Video,
} from './style'

const Atf = ({children}) => {

  return(
    <AtfFull>
      <VideoContainer>
        <Video autoPlay="autoplay" loop="loop" muted playsInline>
          <source src={ATFVideo} type="video/mp4" />
        </Video>
      </VideoContainer>
      <AtfInner>{children}</AtfInner>
    </AtfFull>
  )
}

Atf.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Atf
