import styled from 'styled-components'
import { darken } from 'polished'
import { breakpoint } from 'src/styles/mixins'
import MyAniLink from 'src/components/MyAniLink'

export const Box = styled.div`
  background-color: ${props => props.color};
  height: 80px;
  width: 100%;
  border-radius: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  position: relative;

  &:hover {
    background-color: ${props => darken(0.1, props.color)};
  }

  ${breakpoint.lg`
    margin-top: -164px;
    display: block;
    width: 184px;
    height: 184px;
    border-radius: 4px;
    margin-right: 20px;
  `}
`

export const MyStyledAniLink = styled(MyAniLink)``

export const Heading = styled.h2`
  font-size: ${props => props.theme.fontSize.text};
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.font.primary};
  margin: 0;

  ${breakpoint.lg`
    font-size: 1.25rem;
  `}
`

export const Text = styled.p`
  font-size: ${props => props.theme.fontSize.text};
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.font.primary};
  line-height: 1.55;
  margin-top: 12px;
  margin-bottom: 20px;
  display: none;

  ${breakpoint.lg`
    display: block;
  `}
`

export const Arrow = styled.img`
  height: 16px;

  ${breakpoint.lg`
    position: absolute;
    left: 20px;
    bottom: 20px;
  `}
`
