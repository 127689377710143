import React from 'react'
import PropTypes from 'prop-types'
import MyCard from 'src/components/MyCard'
import Wysiwyg from 'src/components/Wysiwyg'
import { Grid } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

const Cards = ({ data, children }) => {
  const mappedCards = data.map(card => {
    return (
      <Grid item xs={12} md={4} key={uuidv4()}>
        <MyCard
          fluid={card.image.localFile.childImageSharp.fluid}
          heading={card.heading}
        >
          <Wysiwyg>{card.textWysiwyg}</Wysiwyg>
        </MyCard>
      </Grid>
    )
  })

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        spacing={2}
      >
        {mappedCards}
      </Grid>
      {children}
    </>
  )
}

Cards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object, // eslint-disable-line
      heading: PropTypes.string,
      text_wysiwyg: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
}

Cards.defaultProps = {
  children: null,
}

export default Cards
