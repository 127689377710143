import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import { Grid, Box } from '@material-ui/core/'
import SectionPattern from 'src/components/SectionPattern'
import Heading2Line from 'src/components/Heading2Line'
import LogoGrid from 'src/components/LogoGrid'
import Wysiwyg from 'src/components/Wysiwyg'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { getThemeVar } from 'src/styles/mixins'

import quote from 'src/img/quote-green.svg'

import {
  Testimonial,
  Portrait,
  Citation,
  Name,
  Title,
  BackgroundPattern,
  Wrapper,
  TestimonialWrapper,
} from './style'

const Partners = ({ partners }) => {

  const breakpoints = useBreakpoint();

  return (
    <Section background={getThemeVar('color.lightblue')} hasPadding>
      <Heading2Line
        preHeading={partners.preHeading}
        heading={partners.heading}
      />
      <Box mt={8}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Wrapper item xs={12} md={5}>
            <Testimonial>
              <TestimonialWrapper>
                <Grid container item xs={12}>
                  <Grid item xs={12} lg={7} xl={6}>
                    <Box
                      display="flex"
                      justifyContent={{ xs: 'center', lg: 'start' }}
                    >
                      <Portrait
                        fluid={
                          partners.testimonial.portrait.localFile
                            .childImageSharp.fluid
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={5} xl={6}>
                    <Box
                      display={{ xs: 'flex', lg: 'block' }}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Citation src={quote} alt="" />
                      <Name>{partners.testimonial.name}</Name>
                      <Title>{partners.testimonial.title}</Title>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4}>
                    <Wysiwyg>{partners.testimonial.textWysiwyg}</Wysiwyg>
                  </Box>
                </Grid>
              </TestimonialWrapper>
              <BackgroundPattern />
            </Testimonial>
          </Wrapper>

          <Grid item xs={1} />
          <Grid container item xs={12} md={6} spacing={0}>
            <LogoGrid logos={partners.logos} chunks={breakpoints.md ? 6 : 4} />
          </Grid>
        </Grid>
      </Box>
      <SectionPattern positionX="right" />
    </Section>
  )
}

Partners.propTypes = {
  partners: PropTypes.shape({
    preHeading: PropTypes.string,
    heading: PropTypes.string,
    testimonial: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      textWysiwyg: PropTypes.string,
      portrait: PropTypes.object, // eslint-disable-line
    }),
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        portrait: PropTypes.object, // eslint-disable-line
      })
    ),
  }).isRequired,
}

export default Partners
