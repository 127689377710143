import styled from 'styled-components'
import Img from 'gatsby-image'
import { TextDefaultStyle, TextBoldStyle } from 'src/styles/typography'
import { Grid } from '@material-ui/core/'
import pattern from 'src/img/background_pattern.svg'
import { breakpoint } from 'src/styles/mixins'

export const Testimonial = styled.div`
  position: relative;
`

export const TestimonialWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.white};
  padding: 32px;
  z-index: 2;
`

export const Portrait = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 75px;
`

export const Citation = styled.img`
  color: green;
  margin: 32px 0 16px 0;

  ${breakpoint.lg`
    margin: 16px 0;
  `}
`

export const Name = styled.h2`
  ${TextBoldStyle}
  font-size: 20px;
  margin: 0 0 8px 0;
  text-align: center;

  ${breakpoint.lg`
    text-align: left;
  `}
`

export const Title = styled.p`
  ${TextDefaultStyle}
  color: ${props => props.theme.color.grey};
  margin: 4px 0;
  text-align: center;

  ${breakpoint.lg`
    text-align: left;
  `}
`

export const Wrapper = styled(Grid)`
  position: relative;
  width: 100%;
`

export const BackgroundPattern = styled.div`
  position: absolute;
  bottom: -24px;
  left: -24px;
  height: 100%;
  width: 100%;
  border: none;
  background-image: url(${pattern});
  z-index: 1;
`
