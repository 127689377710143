import styled, {css, keyframes} from 'styled-components'

const topNode = keyframes`
  0% {transform:scale(0.5);left:0;z-index:10;opacity:0.75;}
  25% {transform:scale(0.75);opacity:1;}
  50% {transform:scale(0.5);left:32px;z-index:0;opacity:0.75;}
  75% {transform:scale(0.25);opacity:0.5;}
  100% {transform:scale(0.5);left:0;opacity:0.75;}
`

const bottomNode = keyframes`
  0% {transform:scale(0.5);right:0; opacity:0.75;}
  25% {transform:scale(0.25);opacity:0.5;}
  50% {transform:scale(0.5);right:32px; opacity:0.75;}
  75% {transform:scale(0.75); opacity:1;}
  100% {transform:scale(0.5);right:0; opacity:0.75;}
`

export const DnaWrapper = styled.div`
  display: flex;
`

export const DnaSection = styled.div`
  position: relative;
  margin: 0 auto;
  width: 50px;
  height: 250px;
  display: inline-block;
`

const NodeStyle = css`
  position: absolute;
  top: ${props => props.index * 16}px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${props => props.theme.color.green};
  animation:4s ${topNode} ease-in-out infinite;
  animation-delay: ${props => props.index * -300}ms;
`

export const TopNode = styled.div`
  ${NodeStyle}
`

export const BottomNode = styled.div`
  ${NodeStyle}
  top: ${props => props.index * 16}px;
  left: auto;
  right: 0;
  background: ${props => props.theme.color.purple};;
  animation: 4s ${bottomNode} ease-in-out infinite;
  animation-delay: ${props => props.index * -300}ms;
`
