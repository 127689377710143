import React from 'react'
import PropTypes from 'prop-types'
import ArrowSliderLeft from 'src/img/arrow-slider-left.svg'
import ArrowSliderRight from 'src/img/arrow-slider-right.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Arrow from './style'

const SlickArrow = ({ onClick, isPrev }) => (
  <Arrow
    onClick={onClick}
    className={`slick-arrow ${isPrev ? `slick-prev` : `slick-next`}`}
    aria-label={`${isPrev ? `previous` : `next`}`}
    type="button"
  >
    {
      isPrev && (
        <>
          <img src={ArrowSliderLeft} alt="" />
          Previous
        </>
      )
    }
    {
      !isPrev && (
        <>
          <img src={ArrowSliderRight} alt="" />
          Next
        </>
      )
    }

  </Arrow>
);

SlickArrow.defaultProps = {
  onClick: undefined,
  isPrev: false,
}

SlickArrow.propTypes = {
  onClick: PropTypes.func,
  isPrev: PropTypes.bool,
}

export default SlickArrow
