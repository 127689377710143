import React from 'react'
import PropTypes from 'prop-types'
// import MyAniLink from 'src/components/MyAniLink'
import arrow from 'src/img/arrow-right.svg'
import { Box, Heading, Text, Arrow, MyStyledAniLink } from './style'

const LinkBox = ({ heading, text, color, relativeUrl }) => (
  <MyStyledAniLink to={relativeUrl}>
    <Box color={color}>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>
      <Arrow src={arrow} alt="" />
    </Box>
  </MyStyledAniLink>
)

LinkBox.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  relativeUrl: PropTypes.string.isRequired,
  color: PropTypes.string,
}

LinkBox.defaultProps = {
  color: '#ffffff',
}

export default LinkBox
