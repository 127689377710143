import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@material-ui/core/'
import Wysiwyg from 'src/components/Wysiwyg'
import ImageWithBackground from 'src/components/ImageWithBackground'
import { getRelativeUrl } from 'src/utils/urlUtils'

import {
  Heading,
  StyledGrid,
  ReorderText,
  ReorderImage,
  StyledGridItemTop,
  StyledGridItemBottom,
  StyledLinkWithArrow,
} from './style'

const DevelopmentFields = ({ fields }) => {
  return (
    <Box my={8}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        spacing={8}
      >
        <Grid item xs={12} md={6}>
          <ImageWithBackground
            imgFluid={fields[0].image.localFile.childImageSharp.fluid}
          />
          <Box my={4}>
            <Heading>{fields[0].heading}</Heading>
            <Wysiwyg>{fields[0].textWysiwyg}</Wysiwyg>
            <StyledLinkWithArrow to={getRelativeUrl(fields[0].link.url)}>
              {fields[0].link.title}
            </StyledLinkWithArrow>
          </Box>
        </Grid>

        <StyledGrid item xs={12} md={6}>
          <ReorderText>
            <Box my={4}>
              <Heading>{fields[1].heading}</Heading>
              <Wysiwyg>{fields[1].textWysiwyg}</Wysiwyg>
              <StyledLinkWithArrow to={getRelativeUrl(fields[1].link.url)}>
                {fields[1].link.title}
              </StyledLinkWithArrow>
            </Box>
          </ReorderText>
          <ReorderImage>
            <ImageWithBackground
              imgFluid={fields[1].image.localFile.childImageSharp.fluid}
              position="right"
            />
          </ReorderImage>
        </StyledGrid>

        <StyledGridItemTop item xs={12} md={6}>
          <ImageWithBackground
            imgFluid={fields[2].image.localFile.childImageSharp.fluid}
          />
        </StyledGridItemTop>

        <StyledGridItemBottom item xs={12} md={6}>
          <Box mt={4}>
            <Heading>{fields[2].heading}</Heading>
            <Wysiwyg>{fields[2].textWysiwyg}</Wysiwyg>
            <StyledLinkWithArrow to={getRelativeUrl(fields[2].link.url)}>
              {fields[2].link.title}
            </StyledLinkWithArrow>
          </Box>
        </StyledGridItemBottom>
      </Grid>
    </Box>
  )
}

DevelopmentFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      image: PropTypes.object, // eslint-disable-line
    })
  ).isRequired,
}

export default DevelopmentFields
