import React from 'react'
import PropTypes from 'prop-types'
import arrayChunk from 'src/utils/arrayUtils'
import { v4 as uuidv4 } from 'uuid'
import SlickArrow from 'src/components/SlickArrow'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  CssGrid,
  Logo,
  LogoSlider,
} from './style'

const LogoGrid = ({logos, chunks}) => (
  <LogoSlider
    infinite
    dots={false}
    autoplay={false}
    nextArrow={<SlickArrow />}
    prevArrow={<SlickArrow isPrev />}
  >
    {
      arrayChunk(logos, chunks).map(slideLogos => (
        <CssGrid key={uuidv4()}>
          {
            slideLogos.map(logo => (
              <Logo key={uuidv4()} fluid={logo.localFile.childImageSharp.fluid} />
            ))
          }
        </CssGrid>
      ))
    }
  </LogoSlider>
)

LogoGrid.defaultProps = {
  chunks: 6,
}

LogoGrid.propTypes = {
  logos: PropTypes.array.isRequired, //eslint-disable-line
  chunks: PropTypes.number,
}

export default LogoGrid
