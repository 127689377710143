/*
* Splits larger array into one array with smaller chunks of sub-arrays
*/

/* eslint-disable no-param-reassign */
/* Side-Effects of no-param-reassign not relevant in reduce,
 * see: https://stackoverflow.com/questions/41625399/how-to-handle-eslint-no-param-reassign-rule-in-array-prototype-reduce-function
*/
const arrayChunk = (logos, chunks) => (
  logos.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/chunks)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
)
/* eslint-disable no-param-reassign */

export default arrayChunk
