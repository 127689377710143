import styled from 'styled-components'
import { breakpoint } from 'src/styles/mixins'

const Arrow = styled.button`
  top: inherit;
  bottom: -50px;

  &.slick-prev {
    left: 0;

    &:before {
      content: none;
    }
  }

  &.slick-next {
    right: 0;

    &:before {
      content: none;
    }
  }

  ${breakpoint.md`
    top: 50%;

    &.slick-prev {
      left: -32px;
    }

    &.slick-next {
      right: -32px;
    }`
  }
`

export default Arrow
