import styled from 'styled-components'
import { MaxWidthBlockStyle } from 'src/styles/layout'
import { breakpoint } from '../../styles/mixins'

export const AtfFull = styled.div`
  min-height: 80vh;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);

  ${breakpoint.lg`
    min-height: 70vh;
  `}
`

export const AtfInner = styled.div`
  ${MaxWidthBlockStyle}
  padding: 40px 16px;
`

export const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  max-height: 140vh;
  overflow: hidden;
  z-index: -1;

  ${breakpoint.lg`
    height: 70vh;
  `}
`

export const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100%;
`
