import styled from 'styled-components'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import { breakpoint } from 'src/styles/mixins'

/*
* React Slick adds inline style, so important is necessary here.
* Ref: https://styled-components.com/docs/faqs#how-can-i-override-inline-styles
*/
export const CssGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-column: 2;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;

  &[style] {
    display: grid !important;
  }

  ${breakpoint.md`
    grid-column: 3;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

export const Logo = styled(Img)`
  background-color: ${props => props.theme.color.white};
  padding: 16px;
  border: 1px dashed ${props => props.theme.color.lightgrey};

  img {
    padding: 24px;
  }
`

export const LogoSlider = styled(Slider)`
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
`
