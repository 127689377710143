import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  DnaWrapper,
  DnaSection,
  TopNode,
  BottomNode,
} from './style'

const AnimatedSeperator = () =>  {

  /*
   * Used empty array here to create DNA-Nodes.
   * Could do the same with index & counter vars and a while loop outside of the return-statement.
   * In my opinion, it is more readable with map inside the return.
   * If necessary some time, Array Length could be refactored as prop for AnimatedSeperator.
  */

  const nodes = new Array(15).fill(undefined);

  return(
    <DnaWrapper>
      <DnaSection>
        {
          nodes.map((node, index) => (
            <React.Fragment key={uuidv4()}>
              <TopNode index={index} />
              <BottomNode index={index} />
            </React.Fragment>
          ))
        }
      </DnaSection>
    </DnaWrapper>
  )
}

export default AnimatedSeperator
